/**
 * WARNING: Do not add more imports into this file! It is used in a quick-fetch, and imports
 * will bloat the quick-fetch bundle, slowing down the app.
 */
import { getPageTypeLocalStorageKey } from '../app/utils/getPageTypeLocalStorageKey';
import { BOARD, LIST } from '../views/constants/PageType';
export const getPageType = (pathname, objectTypeId) => {
  if (pathname.match('/board')) {
    return BOARD;
  }
  if (pathname.match('/list')) {
    return LIST;
  }
  try {
    var _localStorage$getItem;
    const mostRecentPageType = JSON.parse((_localStorage$getItem = localStorage.getItem(`LocalSettings:Sales:${getPageTypeLocalStorageKey(objectTypeId)}`)) !== null && _localStorage$getItem !== void 0 ? _localStorage$getItem : '');
    if (mostRecentPageType === BOARD) {
      return BOARD;
    }
    if (mostRecentPageType === LIST) {
      return LIST;
    }
  } catch (e) {
    // do nothing
  }
  return undefined;
};